import React, { Component, Fragment } from "react";
import {
  FormControlLabel,
  Grid,
  withMobileDialog,
} from "@material-ui/core";
import {IconButton} from "@sterling-react/button";
import DeleteIcon from "@material-ui/icons/Delete";
import { Checkbox } from "../fields/Checkbox";
import { Button } from "../fields/Button";

import { FormattedMessage, IntlProvider, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  loginWithPasswordWatcher,
  rememberMe,
  removeProvider,
} from "../../redux/actions/loginactioncreators";
import ResetPassword from "./ResetPassword";
import UsernameEntry from "./UsernameEntry";
import BackToUserNameEntry from "./BackToUserNameEntry";
import LoginFormPassword from "./LoginFormPassword";
import ResetPasswordLink from "./ResetPasswordLink";
import Blurb from "../Blurb";
import PropTypes from "prop-types";
import providerShape from "../../proptypes/providerShape";
import OptOut from "./OptOut";
import { ProviderDao } from "../../redux/helpers/launchProvider";

export class LoginForm extends Component {
  constructor(props) {
    super(props);
    let saveUsername = JSON.parse(localStorage.getItem("save-username"));
    if (!saveUsername) {
      saveUsername = false;
    }
    let username = JSON.parse(localStorage.getItem("username"));
    if (!username) {
      username = "";
    }
    this.providerDao = new ProviderDao(this.props.client_id);
    this.providerDao.initProvider(this.props.autoIdpRedirectProviderData);
    const savedProvider = this.providerDao.getSavedProvider();

    this.state = {
      redirecting: false,
      lastProvider: savedProvider,
      showProviderOptions:
        savedProvider?.type !== "COGNITO" &&
        props.enableAutoIdpRedirect === true,
      enableAutoIdpRedirectBool: props.enableAutoIdpRedirect === true,
      autolaunchProvider: savedProvider?.autoLaunch ?? false,
      showPasswordResetDialog: false,
      loginErrorMessages: [],
      validationErrors: {},
      username: username,
      password: null,
      saveUsername: saveUsername,
    };
  }

  saveUsernameInLocalStorage(save) {
    localStorage.setItem("save-username", JSON.stringify(save));
    if (save) {
      if (this.state.username) {
        localStorage.setItem("username", JSON.stringify(this.state.username));
      }
    } else {
      localStorage.removeItem("username");
    }
  }

  handleLaunchProvider = (event) => {
    this.providerDao.launchProvider(this.state.lastProvider);
    event.preventDefault();
  };

  handleOnSubmit = (event) => {
    let loginParams = {
      username: this.props.username,
      password: this.props.password,
    };
    this.props.loginWithPasswordWatcher(loginParams);
    event.preventDefault();
  };

  validateLogin() {
    let state = this.state;
    let valid = false;
    if (!this.state.username) {
      state.validationErrors.username = true;
    } else {
      state.validationErrors.username = false;
      valid = true;
    }
    if (!this.state.password) {
      state.validationErrors.password = true;
    } else {
      state.validationErrors.password = false;
      valid = true;
    }
    this.setState(state);
    return valid;
  }

  loginWithGoogle = () => {
    window.location =
      "/oauth2/authorize" +
      window.location.search +
      "&identity_provider=Google";
  };
  handleSaveUsername = (event) => {
    this.props.rememberMe(event.target.value);
  };
  toggleAutoLaunchProvider = (event) => {
    this.setState({ ...this.state, autolaunchProvider: event.target.checked });
    this.providerDao.saveLastLoginProvider(
      this.state.lastProvider,
      event.target.checked
    );
  };
  handleDeleteProvider = () => {
    this.setState({ ...this.state, showProviderOptions: undefined });
    this.providerDao.deleteLastLoginProvider();
  };

  render() {
    const showPassword = this.props.provider;
    const { enableAutoIdpRedirectBool, showProviderOptions, lastProvider } =
      this.state;
    const idpIdentifier = lastProvider?.idpIdentifier;
    const fullWidthClassname = "w-full";
    const bespokeDeleteProviderIconButtonClassname = "text-gray-800 hover:shadow-md align-middle text-center relative select-none transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-6 max-w-[40px] h-6 max-h-[40px] hover:bg-gray-900/10 rounded-sm size-px"
    const bespokeFormControlLabelClassname = "py-2";
    const bespokeSaveUsernameCheckboxClassname = "align-center w-4 h-4 mx-1 accent-gray-800 border-gray-300";
    return (
      <React.Fragment>
        <div className={fullWidthClassname}>
          <Blurb />

          {this.props.step === "CONFIRM_RESET_PASSWORD" && (
            <ResetPassword open={true} />
          )}
          <Grid container className="justify-center mb-4">
            <Grid item xs={12}>
              <UsernameEntry />
            </Grid>
          </Grid>
          {showProviderOptions && idpIdentifier && !showPassword && (
            <Grid container className="justify-start">
              <Grid item xs={12}>
                <Button
                  id="launchProvider"
                  name="launchProvider"
                  onClick={this.handleLaunchProvider}
                >
                  <FormattedMessage id={"login.providerText"} defaultMessage={"Launch your company login for single-sign on"} />
                </Button>

                <IconButton
                  aria-label="Delete saved provider"
                  className={bespokeDeleteProviderIconButtonClassname}
                  variant="text"
                  onClick={this.handleDeleteProvider}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>

                {enableAutoIdpRedirectBool && (
                  <FormControlLabel
                    className={bespokeFormControlLabelClassname}
                    labelPlacement="end"
                    control={
                      <Checkbox
                        onChange={this.toggleAutoLaunchProvider}
                        name="autoLaunchProvider"
                        checked={this.state.autolaunchProvider}
                      />
                    }
                    label={
                      <div>
                        <FormattedMessage id={"login.autoLaunchProviderText"} defaultMessage={"Automatically launch your company login for single-sign on"} />  
                      </div>
                    }
                  />
                )}
              </Grid>
            </Grid>
          )}
          {showPassword && (
            <Fragment>
              <Grid container className="justify-center mb-4">
                <Grid item xs={12}>
                  <LoginFormPassword />
                </Grid>
                {this.props.allowClassicLoginExperience &&
                  this.props.failedLogins >= 3 && (
                    <Grid container className="justify-end">
                      <Grid item>
                        <OptOut />
                      </Grid>
                    </Grid>
                  )}
                <Grid item xs={7} className="px-3 py-2">
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!this.props.username}
                          onChange={this.handleSaveUsername}
                          name="saveUsername"
                          className={bespokeSaveUsernameCheckboxClassname}
                          checked={this.props.remembered}
                        />
                      }
                      label={<FormattedMessage id={"login.saveUsername"} defaultMessage={"Remember me"} />}
                    />
                </Grid>
                <Grid item xs={5} className="py-2 justify-end flex">
                  <ResetPasswordLink />
                </Grid>
              </Grid>
              <br />
              <Grid container className="justify-end">
                <Grid item>
                  <BackToUserNameEntry />
                </Grid>
                <Grid item>
                  <Button
                    id="submitSignIn"
                    name="submitSignIn"
                    onClick={this.handleOnSubmit}
                    color="primary"
                    disabled={!this.props.password}
                  >
                    <FormattedMessage id={"login.signIn"} defaultMessage={"Sign In"} />
                  </Button>
                </Grid>
              </Grid>
            </Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

LoginForm.propTypes = {
  failedLogins: PropTypes.number.isRequired,
  setPassword: PropTypes.func.isRequired,
  loginWithPasswordWatcher: PropTypes.func.isRequired,
  username: PropTypes.string,
  password: PropTypes.string,
  classes: PropTypes.object.isRequired,
  intl: PropTypes.objectOf(IntlProvider),
  passwordErrors: PropTypes.array,
  remembered: PropTypes.bool,
  step: PropTypes.string,
  autoIdpRedirectProviderData: providerShape,
  provider: providerShape,
  enableAutoIdpRedirect: PropTypes.bool,
  removeProvider: PropTypes.func.isRequired,
  rememberMe: PropTypes.bool,
  client_id: PropTypes.string,
  allowClassicLoginExperience: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    failedLogins: state.loginForm.failedLogins,
    remembered: state.loginForm.remembered,
    password: state.loginForm.password,
    username: state.loginForm.username,
    passwordErrors: state.loginForm.passwordErrors,
    step: state.loginForm.step,
    provider: state.loginForm.provider,
    enableAutoIdpRedirect: state.loginForm.enableAutoIdpRedirect,
    loginErrorMessages: state.loginForm.loginErrorMessages,
    deviceKey: state.loginForm.deviceKey,
    autoIdpRedirectProviderData: state.loginForm.autoIdpRedirectProviderData,
    client_id: state.loginForm.clientId,
    allowClassicLoginExperience: state.loginForm.allowClassicLoginExperience,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loginWithPasswordWatcher,
      removeProvider,
      rememberMe,
      // add other watcher sagas to this object to map them to props
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withMobileDialog()(LoginForm)));
