import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Clarity from '@microsoft/clarity';

export default function bootstrap(node, config) {
  if (config.clarityConfig?.enabled === true && config.clarityConfig.projectId) {
    Clarity.init(config.clarityConfig.projectId)
  }
  ReactDOM.render(
    <App {...config} />,
    node,
  );
}